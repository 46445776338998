import React from "react"
import styled from "styled-components"
import { AiFillGithub, AiFillTwitterCircle } from "react-icons/ai"
import { NavBox } from "./sidenavbox"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9fafe;
  padding: 8px 16px;
`
const Item = styled.div`
  padding: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SocialMediaNav = () => {
  const socials = [
    {
      name: "twitter",
      Icon: AiFillTwitterCircle,
      link: "https://twitter.com/letsaskhow",
    },
    {
      name: "github",
      Icon: AiFillGithub,
      link: "https://github.com/i-m-ram",
    },
  ]
  return (
    <NavBox>
      <Wrapper>
        {socials?.map(item => {
          return (
            <a
              href={item?.link}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#ff7b1c" }}
              key={item?.name}
            >
              <Item>{item.Icon({ size: 30 })}</Item>
            </a>
          )
        })}
      </Wrapper>
    </NavBox>
  )
}
