import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 150px);
`

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
`

const NotFoundPage = () => (
  <Layout>
    <StyledDiv>
      <h2>404: Not Found</h2>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <StyledLink to="/">Back to home</StyledLink>
    </StyledDiv>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
