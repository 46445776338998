import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  margin-top: 32px;
  padding-left: 40px;
  padding-right: 32px;
`

export const Sidenav = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>
}
