import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useWindowSize } from "react-use"
import Header from "./header"
import "./layout.css"
import { Sidenav } from "./sidenav"
import { NavList } from "./navlist"
import { NavBox } from "./sidenavbox"
import { SocialMediaNav } from "./socialmedianav"

const Layout = ({ children }) => {
  const { width } = useWindowSize()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {width < 1000 ? (
        <main className="middle" style={{ margin: "0px 24px" }}>
          {children}
        </main>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "260px 1fr",
            gridAutoRows: "auto",
          }}
        >
          <Sidenav>
            <NavList />
            <SocialMediaNav />
            <NavBox />
            {/* <NavBox>Google Ads placeholder</NavBox> */}
          </Sidenav>
          <main className="middle" style={{ marginRight: "40px" }}>
            {children}
          </main>
        </div>
      )}

      <footer
        style={{
          marginTop: `var(--space-5)`,
          fontSize: `var(--font-sm)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px 0px",
        }}
      >
        © {new Date().getFullYear()} &middot; Copyright &middot; letsaskhow.com
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
